@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

html {
  height: 100%;
}

.Mui-disabled {
  background-color: rgb(165, 165, 165) !important;
}

* {
  font-family: "Poppins", sans-serif;
}
